import styled from 'styled-components';

export const NavWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: fixed;
  width: 100%;
  z-index: 5;
  height: 80px;
  background: rgb(25, 69, 71);
  background: radial-gradient(
    circle,
    rgba(25, 69, 71, 1) 7%,
    rgba(21, 32, 43, 1) 45%
  );
  box-shadow: 0 4px 2px -2px gray;
  box-shadow: rgba(0, 0, 0, 0.25) 0px 5px 10px, rgba(0, 0, 0, 0.22) 0px 5px 10px;
`;
export const NavContainer = styled.div`
  .abs {
    display: none;
  }
  .icon {
    display: none;
    transition: all 0.3s ease-in;
  }
  /* display: flex;
  align-items: center; */
  .count {
    cursor: pointer;
    width: 15px;
    height: 15px;
    background: #4fd8e0;
    position: absolute;
    border-radius: 50%;
    right: 12px;
    top: 2px;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 8px;
    font-weight: 600 !important;
    color: #000;
    font-family: 'Roboto', sans-serif;
    transition: all 0.3s ease-in;
  }
  &:nth-child(2) {
    display: ${(props) => (props.showMe ? 'block' : 'none')};
  }
  @media screen and (max-width: 900px) {
    .abs {
      display: block;
      position: relative;
      cursor: pointer;
    }
    .icon {
      display: block;
      // margin-bottom: -8px;
      cursor: pointer;
      margin-right: 20px;
    }
    &:nth-child(2) {
      /* display: none; */
    }
  }

  form {
    position: relative;
    @media screen and (max-width: 900px) {
      height: 100%;
      width: 100%;
    }
  }
  input {
    width: 30vw;
    margin-right: 1rem;
    border: 0;
    border-radius: 10px;
    height: 40px;
    text-align: center;
    font-weight: 500;
    padding: 0.2rem 0.5rem;
    color: #ffffff;
    background: rgba(42, 163, 239, 0.06);
    transition: all 0.3s linear;
    &::placeholder {
      opacity: 0.7;
    }
    &:focus {
      box-shadow: 0px 0px 5px 0px rgba(0, 0, 0, 0.75);
      /* width: 30vw; */
    }
  }
  .search {
    color: #f1f1f1;
    font-size: 1.2rem;
  }
  .settings {
    color: #f1f1f1;
    font-size: 1.7rem;
  }
  button {
    position: absolute;
    right: 8%;
    top: 0.55rem;
    outline: 0;
    border: 0;
    background: transparent;
    cursor: pointer;
    &:disabled {
      cursor: default;
    }
    @media screen and (max-width: 900px) {
      display: ${(props) => (props.hide ? 'none' : 'block')};
      right: -1rem;
      /* top: 10%; */
    }
  }

  &:first-child {
    padding-left: 2rem;
    cursor: pointer;
    @media screen and (max-width: 900px) {
      padding-left: 0.5rem;
    }
  }
  &:last-child {
    padding-right: 3rem;
    display: flex;
    justify-content: space-between;
  }
`;

export const UserArea = styled.div``;
export const BioArea = styled.div`
  margin-right: 2rem;
  display: flex;
  align-items: center;
  cursor: pointer;
  @media screen and (max-width: 500px) {
    display: none;
  }
`;

export const UserPic = styled.div`
  background-image: url(${(props) => props.src});
  background-size: cover;
  background-position: center;
  height: 40px;
  width: 40px;
  border-radius: 50%;
`;
export const Pic = styled.div``;
export const UserInfo = styled.div`
  margin-left: 1rem;
`;
export const Name = styled.p`
  margin-bottom: 0;
  padding-bottom: 0;
  font-size: 14px;
  font-weight: 500;
  color: #fff;
`;
export const Handle = styled.p`
  margin-top: 0;
  padding-top: 0;
  font-size: 12px;
  font-weight: 400;
  color: #fff;
`;
export const SettingsArea = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  img {
    cursor: pointer;
  }
  .settings {
    cursor: pointer;
    margin-right: 1rem;
    @media screen and (max-width: 900px) {
      margin-right: -2rem;
    }
  }
`;

export const Login = styled.button`
  height: 40px;
  width: 150px;
  display: flex;
  align-items: center;
  justify-content: center;
  background: #f1f1f1 !important;
  color: #000 !important;
  align-self: center;
  position: absolute;
  right: 2rem;
  font-weight: 500;
  font-size: 20px;
  cursor: pointer;
  border-radius: 10px;
`;
