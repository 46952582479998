import {
  NavWrapper,
  NavContainer,
  BioArea,
  UserInfo,
  Name,
  Handle,
  SettingsArea,
  UserPic,
} from '@styles/common/navbar.styles';
import React, { useState } from 'react';
import Image from 'next/image';
import Link from 'next/link';
import PropTypes from 'prop-types';
import { useRouter } from 'next/router';
import { FaSearch } from 'react-icons/fa';
import { FiSettings } from 'react-icons/fi';
import { IoIosNotifications } from 'react-icons/io';
import { useSession } from 'next-auth/react';
import { useSelector } from 'react-redux';

export default function Navbar({ show, showingNotif, count, toggleNotif }) {
  const userinfo = useSelector((state) => state.auth.userInfo);
  const router = useRouter();
  const openUserProfile = () => {
    router.push(`/profile/${userinfo.handle}`);
  };

  const [searchText, setSearchText] = useState('');

  const submitSearch = (e) => {
    e.preventDefault();
    setSearchText('');
    // go to search with searchText param
    router.push(`/search/${searchText}`);
  };

  const { status } = useSession();

  console.log(userinfo);

  return (
    <NavWrapper className="nav">
      <NavContainer>
        <Link href={'/'}>
          <Image
            src="https://res.cloudinary.com/dpnbddror/image/upload/v1641053130/brand_assets/LOGO_k1_rldb7d.webp"
            width="70"
            height="70"
            alt="Karent Logo"
            style={{
              maxWidth: '100%',
              height: 'auto',
            }}
          />
        </Link>
      </NavContainer>
      <NavContainer
        className="search"
        hide={searchText.length < 3}
        showMe={show}
        role="search"
      >
        <form>
          <input
            type="text"
            maxLength={30}
            minLength={3}
            value={searchText}
            onChange={(e) => setSearchText(e.target.value)}
            required
            placeholder="Search"
          />
          <button
            disabled={searchText.length < 3}
            onClick={(e) => submitSearch(e)}
            type="submit"
          >
            <FaSearch className="icon search" />
          </button>
        </form>
      </NavContainer>
      <NavContainer>
        {userinfo ? (
          <BioArea onClick={() => openUserProfile()}>
            <UserPic
              src={
                userinfo?.avatar?.url
                  ? userinfo?.avatar?.url
                  : userinfo?.avatar?.secure_url
                  ? userinfo?.avatar?.secure_url
                  : `https://avatars.navu360.com/api/micah/${userinfo?._id}.svg?mouth[]=laughing&mouth[]=smile&hair[]=mrClean&hair[]=fonze&earringsProbability=0&facialHairProbability=0&backgroundType=gradientLinear,solid&backgroundColor=b6e3f4,c0aede,d1d4f9`
              }
              alt="profile"
              width={40}
              height={40}
            />
            <UserInfo>
              <Name>{userinfo?.firstName + ' ' + userinfo?.lastName}</Name>
              <Handle>{'@' + userinfo?.handle}</Handle>
            </UserInfo>
          </BioArea>
        ) : null}

        {userinfo &&
          (showingNotif ? (
            <IoIosNotifications
              className="icon noti"
              color="#4fd8e0"
              fontSize="2.1rem"
              onClick={() => toggleNotif('toggle')}
            />
          ) : (
            <div className="abs">
              <IoIosNotifications
                className="icon noti"
                color="#f1f1f1"
                fontSize="2.1rem"
                onClick={() => toggleNotif('toggle')}
              />
              {count && <span className="count">{count}</span>}
            </div>
          ))}
        {userinfo && (
          <SettingsArea>
            <FiSettings
              className="settings"
              onClick={() => router.push('/settings')}
              role="link"
            />
          </SettingsArea>
        )}
      </NavContainer>
      {!userinfo?.handle && (
        <Link
          href={status === 'authenticated' ? '/' : '/welcome'}
          className="mr-4 flex max-w-[300px] items-center gap-4 rounded-3xl bg-white px-6 py-2 text-black hover:text-black"
        >
          {status === 'authenticated' ? 'Ready' : 'Continue with google'}
          <svg
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
            strokeWidth={1.5}
            stroke="#f40101"
            className="h-6 w-6"
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              d="M15.59 14.37a6 6 0 01-5.84 7.38v-4.8m5.84-2.58a14.98 14.98 0 006.16-12.12A14.98 14.98 0 009.631 8.41m5.96 5.96a14.926 14.926 0 01-5.841 2.58m-.119-8.54a6 6 0 00-7.381 5.84h4.8m2.581-5.84a14.927 14.927 0 00-2.58 5.84m2.699 2.7c-.103.021-.207.041-.311.06a15.09 15.09 0 01-2.448-2.448 14.9 14.9 0 01.06-.312m-2.24 2.39a4.493 4.493 0 00-1.757 4.306 4.493 4.493 0 004.306-1.758M16.5 9a1.5 1.5 0 11-3 0 1.5 1.5 0 013 0z"
            />
          </svg>
        </Link>
      )}
    </NavWrapper>
  );
}

// prop types
Navbar.propTypes = {
  show: PropTypes.bool.isRequired,
  userinfo: PropTypes.object,
  isLoggedIn: PropTypes.any,
  showingNotif: PropTypes.bool,
  count: PropTypes.number,
  toggleNotif: PropTypes.func,
};
