import styled, { keyframes, css } from 'styled-components';

export const Show = keyframes`
0% {
  transform: translateX(-20vw);
  opacity: 0.5;
}
100% {
  transform: translateX(0);
  opacity: 1;
}
`;

export const Hide = keyframes`
0% {
  transform: translateX(0);
  opacity: 1;
}
100% {
  transform: translateX(-20vw);
  opacity: 0.001;
}
`;
export const Wrapper = styled.div`
  position: absolute;
  /* width: 50%; */
  left: 3rem;
  top: 15vh;
  height: 50px;
  ${(props) =>
    props?.beenchanged !== 'false'
      ? css`
          animation: ${Hide} 0.5s linear;
        `
      : css`
          animation: ${Show} 0.5s linear;
        `}
  display: flex;
  flex-direction: row;
  align-items: center;
  z-index: 6;

  p {
    background-color: #f1f1f1;
    border-radius: 0 10px 10px 0;
    color: #000;
    width: max-content;
    max-width: max-content;
    min-width: 50%;
    padding: 0.5rem 1rem;
    border-left: ${(props) =>
      props.isshowred === 'false' ? '3px solid green' : '3px solid red'};
  }
  @media screen and (max-width: 900px) {
    left: 1rem;
    top: 5vh;
    font-size: smaller;
  }
`;

export const Spinner = keyframes`
to {transform: rotate(360deg);}
`;
export const Centered = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  /* height: 100%; */
  /* min-height: 50vh; */
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  bottom: 0;
  right: 0;

  .spinner {
    position: relative;
  }

  .spinner::after {
    content: '';
    box-sizing: border-box;
    width: 40px;
    height: 40px;
    position: absolute;
    top: calc(50% - 20px);
    left: calc(50% - 20px);
    border-radius: 50%;
  }
  .spinner.smooth::after {
    border-top: 4px solid rgba(255, 255, 255, 1);
    border-left: 4px solid rgba(255, 255, 255, 1);
    border-right: 4px solid rgba(255, 255, 255, 0);
    animation: ${Spinner} 0.6s linear infinite;
  }
`;

export const PulseAnimation = keyframes`
0% {
  transform: scale(1);
}
50% {
  transform: scale(1.1);
}
100% {
  transform: scale(1);
}`;
export const WrapperSpinner = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  height: 100vh;
  width: 100vw;

  display: flex;
  justify-content: center;
  align-items: center;
  color: #000;
  background: rgb(25, 69, 71);
  background: radial-gradient(
    circle,
    rgba(25, 69, 71, 1) 7%,
    rgba(21, 32, 43, 1) 74%
  );

  .spinner::after {
    content: '';
    box-sizing: border-box;
    width: 40px;
    height: 40px;
    position: absolute;
    top: calc(50% - 20px);
    left: calc(50% - 20px);
    border-radius: 50%;
  }
  .spinner.smooth::after {
    border-top: 4px solid rgba(255, 255, 255, 1);
    border-left: 4px solid rgba(255, 255, 255, 1);
    border-right: 4px solid rgba(255, 255, 255, 0);
    animation: ${Spinner} 0.6s linear infinite;
  }
  .image {
    animation: ${PulseAnimation} 1s linear infinite;
  }
`;

export const AnimateDots = keyframes`
0% {
      left: 100px;
      top: 0;
    }
    80% {
      left: 0;
      top: 0;
    }
    85% {
      left: 0;
      top: -20px;
      width: 20px;
      height: 20px;
    }
    90% {
      width: 40px;
      height: 15px;
    }
    95% {
      left: 100px;
      top: -20px;
      width: 20px;
      height: 20px;
    }
    100% {
      left: 100px;
      top: 0;
    }
`;

export const DotsAnimate = keyframes`
0% {
    transform: rotateZ(0deg) translate3d(0, 0, 0);
  }
  100% {
    transform: rotateZ(720deg) translate3d(0, 0, 0);
  }`;

export const Dots = styled.div`
  position: relative;
  top: -10px;
  width: 5px;
  height: 5px;
  border-radius: 2.5px;
  background-color: #9880ff;
  color: #9880ff;
  transform-origin: 2.5px 10px;
  animation: ${DotsAnimate} 2s infinite linear;

  &::before,
  &::after {
    content: '';
    display: inline-block;
    position: absolute;
  }
  &::before {
    left: -4.66px;
    top: 10px;
    width: 5px;
    height: 5px;
    border-radius: 2.5px;
    background-color: #9880ff;
    color: #9880ff;
  }
  &::after {
    left: 4.66px;
    top: 10px;
    width: 5px;
    height: 5px;
    border-radius: 2.5px;
    background-color: #9880ff;
    color: #9880ff;
  }
`;
