import { GeneralWrapper } from '@styles/layouts/layout.styles';
import React from 'react';
import PropTypes from 'prop-types';

export default function GeneralLayout({ children }) {
  return <GeneralWrapper>{children}</GeneralWrapper>;
}

GeneralLayout.propTypes = {
  children: PropTypes.node.isRequired,
};
