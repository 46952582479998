import React from 'react';
import { WrapperSpinner, Centered, Dots } from './index.styles';
import Image from 'next/image';

import PropTypes from 'prop-types';

export default function KarentSpinner() {
  return (
    <WrapperSpinner>
      <Image
        className="image"
        src="https://res.cloudinary.com/dpnbddror/image/upload/w_100/v1641053130/brand_assets/LOGO_k1_rldb7d.webp"
        width={100}
        height={100}
        alt="Loading..."
        style={{
          maxWidth: '100%',
          height: 'auto',
        }}
      />
    </WrapperSpinner>
  );
}

export function CenteredSpinner({ text }) {
  return (
    <Centered>
      <span className="smooth spinner" />
      <h2 style={{ color: '#f1f1f1', marginTop: '2rem', fontWeight: '500' }}>
        {text}
      </h2>
    </Centered>
  );
}

CenteredSpinner.propTypes = {
  text: PropTypes.string,
};

export function DotsLoading() {
  return (
    <Dots>
      <div className="snippet" data-title=".dot-windmill">
        <div className="stage">
          <div className="dot-windmill"></div>
        </div>
      </div>
    </Dots>
  );
}
