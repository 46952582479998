import styled, { keyframes, css } from 'styled-components';

export const Spinner = keyframes`
to {transform: rotate(360deg);}
`;

export const GeneralWrapper = styled.div`
  background-color: unset;
  @media screen and (min-width: 900px) {
    .web {
      display: block;
    }
    .mobile {
      display: none;
    }
  }
  @media screen and (max-width: 900px) {
    .web {
      display: none;
    }
    .mobile {
      display: block;
    }
  }
`;

export const MainSection = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  padding-top: 6rem;
  // padding-right: 4rem;
  z-index: 4;
  .add {
    cursor: pointer;
    display: none;
  }

  @media screen and (max-width: 900px) {
    flex-direction: column;
    position: relative;
    .add {
      display: block;
      z-index: 10;
    }
  }
  .link {
    width: 90%;
    padding: 1.5rem;
    margin: auto;
    background: rgba(42, 163, 239, 0.08);
    border-radius: 30px;
    margin-bottom: 3rem;
    color: #f1f1f1;
    z-index: 2;
    position: relative;

    @media only screen and (min-width: 900px) and (max-width: 1200px) {
      width: 70%;
    }
    @media screen and (max-width: 900px) {
      width: 80vw;
      position: relative;
      /* margin-left: 1rem; */
    }
    .text {
      padding: 0.8rem;
    }
    .icon {
      transition: all 0.3s linear;
      cursor: pointer;
      &:hover {
        color: red;
      }
    }

    .center {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
    }

    .smooth {
      width: 100%;
      height: 100px;
      position: relative;
    }

    .spinner::after {
      content: '';
      box-sizing: border-box;
      width: 40px;
      height: 40px;
      position: absolute;
      top: calc(50% - 20px);
      left: calc(50% - 20px);
      border-radius: 50%;
    }
    .spinner.smooth::after {
      border-top: 4px solid rgba(255, 255, 255, 1);
      border-left: 4px solid rgba(255, 255, 255, 1);
      border-right: 4px solid rgba(255, 255, 255, 0);
      animation: ${Spinner} 0.6s linear infinite;
    }
    ${(props) =>
      props?.fromview &&
      css`
        /* margin-top: -4rem; */
        padding-top: 4rem;
        width: 45vw;
        height: auto;
        @media screen and (max-width: 900px) {
          width: 100%;
          margin-right: 0;
        }
      `}
    .back {
      position: fixed;
      top: 5rem;
      left: 23vw;
      right: 27.6vw;
      background: rgb(25, 69, 71);
      background: radial-gradient(
        circle,
        rgba(25, 69, 71, 1) 7%,
        rgba(21, 32, 43, 1) 74%
      );
      height: 50px;
      z-index: 10;
      display: flex;
      align-items: center;
      /* border-radius: 10px; */
      @media screen and (max-width: 900px) {
        left: 0;
        right: 0;
      }
    }
    .backicon {
      font-size: 2rem;
      margin-left: 1.5rem;
      &:hover {
        color: #4fd8e0;
      }
    }
    text-decoration: none;
    display: block;
  }

  .hold-content {
    width: 45vw;
  }
  @media screen and (max-width: 768px) {
    .hold-content {
      width: 100%;
    }
  }
`;

export const CurrentContent = styled.div`
  width: 100%;
`;
export const LeftNav = styled.div``;
export const MainFeed = styled.div``;
export const Notifications = styled.div``;
