import axios from 'axios';
import Resizer from 'react-image-file-resizer';

let uploadedMedia = [];

const uploadOne = async (files) => {
  const responses = [];
  uploadedMedia = [];
  for (let i = 0; i < files.length; i++) {
    // image size of file[i]
    const resizeFile = (file) =>
      // eslint-disable-next-line no-undef
      new Promise((resolve) => {
        Resizer.imageFileResizer(
          file,
          500,
          800,
          'PNG',
          100,
          0,
          (uri) => {
            resolve(uri);
          },
          'blob',
        );
      });

    const image = await resizeFile(files[i]);

    const data = new FormData();
    data.append('file', image);
    data.append('upload_preset', process.env.NEXT_PUBLIC_PRESET);
    data.append(`api_key`, process.env.NEXT_PUBLIC_CLOUD_KEY);
    data.append('tags', 'browser_upload');
    responses.push(
      await axios.post(
        `https://api.cloudinary.com/v1_1/karentstatic/upload`,
        data,
      ),
    );
  }
  // add responses as objects to uploadedMedia array
  for (let i = 0; i < responses.length; i++) {
    const ImageObj = {
      url: responses[i].data.secure_url,
      public_id: responses[i].data.public_id,
    };
    uploadedMedia.push(ImageObj);
  }
  return uploadedMedia;
};

export function uploadAllToCloudinary(files) {
  if (files.length === 0) return [];
  // later
  return uploadOne(files);
}
